import orion from "./images/orion.svg";
import tailwine from "./images/tailwine.svg";

export const products = [
  {
    title: "The Orion Framework",
    description: "A developer tool, for a developer, by a developer.",
    link: "https://orion.mrmehta.in",
    logo: orion,
  },
  {
    title: "Tailwine Studio",
    description: "A project suite for your next big adventure.",
    link: "https://www.tailwine.in",
    logo: tailwine,
  },
];
