import Twitter from "./images/x.webp";
import Github from "./images/github.svg";
import indie from "./images/indie.webp";
import Lk from "./images/linkedin.webp";
import lightening from "./images/lightening2.jpeg";
import hashnode from "./images/hashnode.png";
import ddt from "./images/dev.png";
import resume from "./images/drive.png";

export const links = [
  {
    href: "https://drive.google.com/file/d/17uesQIRe5-oICgWr8owlohqaNn9c6ou9/view?usp=sharing",
    imgSrc: resume,
    imgAlt: "Resume Logo",
    bgColor: "#808080",
    category: "Doc",
    title: "Resume",
    desc: "Take an overview of my expertise in full-stack development & building scalable solutions.",
  },
  {
    href: "http://x.mrmehta.in",
    imgSrc: Twitter,
    imgAlt: "X Logo",
    bgColor: "#000",
    category: "Social",
    title: "X",
    desc: "Follow me on X to see my thoughts on development trends and the latest in software engineering.",
  },
  {
    href: "https://www.indiehackers.com/mehta",
    imgSrc: indie,
    imgAlt: "IH Logo",
    bgColor: "#0e2439",
    category: "Social",
    title: "Indie Hacker",
    desc: "Check out my Indie Hacker profile for insights on building efficient, developer-focused solutions.",
  },
  {
    href: "http://linkedin.mrmehta.in",
    imgSrc: Lk,
    imgAlt: "LinkedIn Logo",
    bgColor: "#0A66C2",
    category: "Social",
    title: "LinkedIn",
    desc: "Connect with me on LinkedIn to explore my professional journey and discover opportunities in software development.",
  },
  {
    href: "https://lightning.ai/mehta",
    imgSrc: lightening,
    imgAlt: "Lightening Logo",
    bgColor: "#6157eb",
    category: "AI",
    title: "Lightening AI",
    desc: "Explore my AI agents built on the Lightning AI, designed to stay ahead in AI and create revolutionary integrations.",
  },
  {
    href: "http://github.mrmehta.in",
    imgSrc: Github,
    imgAlt: "GitHub Logo",
    bgColor: "#6e5494",
    category: "Code",
    title: "GitHub",
    desc: "Check out my GitHub to collaborate on code repositories and explore my contributions to open-source projects.",
  },
  {
    href: "http://writer.mrmehta.in",
    imgSrc: hashnode,
    imgAlt: "Hashnode Logo",
    bgColor: "#2962FF",
    category: "Blog",
    title: "Hashnode",
    desc: "Read my technical blogs on Hashnode where I share insights and engage with the developer community.",
  },
  {
    href: "http://ddt.mrmehta.in",
    imgSrc: ddt,
    imgAlt: "Dev.to Logo",
    bgColor: "#000",
    category: "Blog",
    title: "Dev.to",
    desc: "Join me on Dev.to to read my articles and participate in discussions with a vibrant community of developers.",
  },
];
