import React from "react";

function ProductCard({ product }) {
  return (
    <a href={product.link} target="_blank" rel="noopener noreferrer">
      <div className="flex flex-row-reverse mb-1 items-center p-2 space-x-reverse space-x-3 transition transform hover:scale-105 duration-200 text-right">
        <img src={product.logo} alt={product.title} className="w-8 h-8" />
        <div>
          <h3 className="text-md text-gray-900 dark:text-gray-100">
            {product.title}
          </h3>
          <p className="text-sm text-gray-600 dark:text-gray-400">
            {product.description}
          </p>
        </div>
      </div>
    </a>
  );
}

export default ProductCard;
