import React, { useState, useEffect } from "react";
import { links } from "./links";
import LinkCard from "./LinkCard";
import { products } from "./products";
import ProductCard from "./ProductCard";

function App() {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    document.documentElement.classList.add(theme);
    return () => {
      document.documentElement.classList.remove(theme);
    };
  }, [theme]);

  useEffect(() => {
    const handleDoubleClick = () => {
      setTheme(theme === "light" ? "dark" : "light");
    };

    document.body.addEventListener("dblclick", handleDoubleClick);

    return () => {
      document.body.removeEventListener("dblclick", handleDoubleClick);
    };
  }, [theme]);

  return (
    <div
      className="flex flex-col items-center justify-center bg-gradient-to-r from-blue-100 via-purple-100 to-blue-100 dark:from-gray-900 dark:via-gray-800 dark:to-gray-700 poppins"
      style={{ minHeight: "100vh" }}
    >
      <div className="text-2xl font-extrabold pt-8 text-gray-900 dark:text-gray-100">
        Kartik Mehta
      </div>
      <div className="text-lg flex justify-center mb-4 text-gray-600 dark:text-gray-400">
        <span className="text-center mb-4 mx-4 md:mx-0 lg:mx-0">
          Engineering and Product Development harmoniously united.
        </span>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 mb-16">
        {links.map((link, index) => (
          <LinkCard key={index} {...link} />
        ))}
      </div>

      <div className="md:fixed md:bottom-4 md:right-4 md:p-4 transition-opacity duration-500 fade-in hidden md:block">
        <div className="space-y-2">
          {products.map((product, index) => (
            <ProductCard key={index} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default App;
